// SOURCE: https://tympanus.net/codrops/2021/08/31/surface-sampling-in-three-js/


import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import * as dat from 'dat.gui'
// import { MeshSurfaceSampler } from './MeshSurfaceSampler.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js'
import { OBJLoader } from 'three/examples/jsm/loaders/OBJLoader.js';
import { MathUtils } from 'three'
import { GUI } from 'dat.gui'
import perlinNoise3d from 'perlin-noise-3d'

import { EffectComposer } from 'three/examples/jsm/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/examples/jsm/postprocessing/RenderPass.js';
// import { UnrealBloomPass } from 'three/examples/jsm/postprocessing/UnrealBloomPass.js';
import { UnrealBloomPass } from './NewBloom.js';


///RENDERER////
const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
// renderer.setClearColor(new THREE.Color("rgb(255, 255, 255)"), 0);
// Define the size and append the <canvas> in our document
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);





////SETUP SCENE
const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
camera.position.set(1, 1, 2);
// Add OrbitControls to allow the user to move in the scene
const controls = new OrbitControls(camera, renderer.domElement);

const scene = new THREE.Scene();



// scene.background =new THREE.Color("blue");       use this if you wanna use the original UnrealBloomPass (change IMPORT setting)



//// ADDING POST PROCESSING

var bloomParams={
    threshold:0.5,
    strength : 1,
    radius :0.1
}

const composer = new EffectComposer( renderer );
const renderPass = new RenderPass( scene, camera );

composer.addPass( renderPass );



const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
				bloomPass.threshold =bloomParams.threshold;
				bloomPass.strength = bloomParams.strength;
				bloomPass.radius =bloomParams.radius;

			
				// composer.addPass( renderScene );
				composer.addPass( bloomPass );

//////OVERALL VARIABLES//////

const pointsGroup = new THREE.Group();
const particleCount = 50000;
const particleSize = 0.01;
const sourceGeometries = [];
const targetGeometries = [];
var pointsGeometry = null;

var params = {
    interpolation: 0,
    particleCount: 5000,
    isInterpolating: false,
    targetGeomIndex: 1,
    maxDisplacement: 0.1,
    noiseFreq: 1,
    maxIdleNoise: 1,
    permanenceTime: 1
};





const textureLoader = new THREE.TextureLoader();
// const particleTexture = textureLoader.load("./blob.png");
// const particleTexture = textureLoader.load("./pngegg.png");
const particleTexture = textureLoader.load("./spark.png");

// const pointsMaterial = new THREE.PointsMaterial({
//     map: particleTexture,
//     blending: THREE.AdditiveBlendings,
//     transparent: true,
//     // alphaTest: 0.5,
//     // opacity: 1,
//     depthWrite: true, //IMPORTANT FOR THE ALPHA MERGE

//     // alphaTest = 0.5,
//     // transparent = false,
//     // color: 'blue',
//     size: particleSize,
//     vertexColors: true // allow multiple colors per vertex

//     //gragmentshader: ?!?! in shadermaterial


// });


const pointsMaterial = new THREE.PointsMaterial({
    map: particleTexture,
    // blending: THREE.MultiplyBlending,
    depthWrite: false,
    blending: THREE.AdditiveBlending,
    size: particleSize,
    vertexColors: true // allow multiple colors per vertex

    //gragmentshader: ?!?! in shadermaterial


});

const torusMat = new THREE.MeshBasicMaterial({
    color: 0x66ccff,
    transparent: true,
    opacity: 0.8,
    wireframe: true

});

///// OBJ IMPORTER //////

function ImportObjToSourceGeom(sourceGeom, callback) {
    console.log("IMPORTING SOURCE GEOMETRIES");

    // var path = "./skull.obj";
    var newObj = null
    var objLoader = new OBJLoader();
    objLoader.setPath("./");
    objLoader.load("skull.obj", function(obj) {
        newObj = obj.children[0];
        sourceGeom.push(newObj.geometry);
        console.log(sourceGeom.length + " SKULL");

        callback();

    });


};



///// CONSTRUCTORS /////////


function TargetGeometry(geom, particlesN) {

    this.geometry = geom;
    this.mesh = '';
    this.sampler = '';
    this.finalPositions = [];
    this.pointGeometry = '';

    this.particleCount = particlesN;


    this.GenerateMesh = function(meshMaterial) {
        this.mesh = new THREE.Mesh(this.geometry, meshMaterial);
    };

    this.UpdateSamplerAndPositionList = function(samplingParticleCount) {
        this.sampler = new MeshSurfaceSampler(this.mesh).build();
        var tempPos = new THREE.Vector3();
        for (let i = 0; i < samplingParticleCount; i++) {
            this.sampler.sample(tempPos);
            this.finalPositions.push(tempPos.x, tempPos.y, tempPos.z);
        }

    }


    this.UpdateSamplerAndPositionListNoisy = function(samplingParticleCount) {
        this.sampler = new MeshSurfaceSampler(this.mesh).build();
        var tempPos = new THREE.Vector3();
        for (let i = 0; i < samplingParticleCount; i++) {


            var idlePosnoise = ((0.5 - MathUtils.clamp(noise.get(i), 0, 1)) * params.maxIdleNoise);
            this.sampler.sample(tempPos);
            this.finalPositions.push(tempPos.x * idlePosnoise, tempPos.y * idlePosnoise, tempPos.z * idlePosnoise);
        }

    }

    //Need to call function on object generation!!!


}




//AUTOMATIC CONSTRUCTOR GENERATOR//


sourceGeometries.push(new THREE.SphereGeometry(1, 10, 10));
sourceGeometries.push(new THREE.SphereGeometry(1, 10, 10));
sourceGeometries.push(new THREE.BoxGeometry(1, 1, 1));
sourceGeometries.push(new THREE.DodecahedronGeometry(1));
// sourceGeometries.push(importObj());


function GenerateTargetGeometries(sourceGeometries, targetGeometries, callback) {
    console.log("GENERATING TAGET GEOM");

    for (var i = 0; i < sourceGeometries.length; i++) {


        const newTargetGeom = new TargetGeometry(sourceGeometries[i]);
        newTargetGeom.GenerateMesh(torusMat);

        //GENERATE NOISY ON FIRST GEOMETRY ASSUMING IT'S THE IDLE
        if (i == 0) {
            newTargetGeom.UpdateSamplerAndPositionListNoisy(particleCount);
        } else {

            newTargetGeom.UpdateSamplerAndPositionList(particleCount);
        }
        targetGeometries.push(newTargetGeom);
    }

    // console.log(targetGeometries.finalPositions + " TARGET GEOMSLEN")
    callback();
}



/// GENERATE POINTS GEOMETRY////


function GeneratePointsGeom(pointsGeom, targetGeometries, callback) {


    console.log("CREATING POINT GEOM");
    pointsGeom = new THREE.BufferGeometry();

    // const textureLoader = new THREE.TextureLoader();
    // const particleTexture = textureLoader.load("./blob.png");



    //set position attributes.
    pointsGeom.setAttribute('position', new THREE.Float32BufferAttribute(targetGeometries[0].finalPositions, 3));

    const colors = [new THREE.Color("#F8F0C9"), new THREE.Color("#F6F9FC"), new THREE.Color("#C8CACA")]
    // const colors = [new THREE.Color("#5E3649"), new THREE.Color("#D36026"), new THREE.Color("#37616F")]
    const setColors = [];
    for (let i = 0; i < particleCount; i++) {

        const color = colors[Math.floor(Math.random() * colors.length)];
        setColors.push(color.r, color.g, color.b);
    }

    pointsGeom.setAttribute('color', new THREE.Float32BufferAttribute(setColors, 3));

    /// GENERATE POINTS "MESH"///

    const points = new THREE.Points(pointsGeom, pointsMaterial);


    pointsGroup.add(points);
    scene.add(pointsGroup);
    UpdateGui();

    //CHECKING SOURCES
    console.log(pointsGeom.attributes.position.count);
    pointsGeometry = pointsGeom;
    console.log(pointsGeometry.attributes.position.count);


    callback();
}




////INIT LOOP////






ImportObjToSourceGeom(sourceGeometries, () => {
    GenerateTargetGeometries(sourceGeometries, targetGeometries, () => {
        GeneratePointsGeom(pointsGeometry, targetGeometries, () => {
            renderer.setAnimationLoop(render);
        })
    });
});





/////ADD GUI////

const gui = new dat.GUI();



function UpdateGui() {
    // gui.add(params, 'particleCount').min(0).max(maxParticleCount);
    // gui.add(params, 'isInterpolating').listen();
    // gui.add(params, 'targetGeomIndex').min(0).max(sourceGeometries.length - 1).step(1);
    gui.add(params, 'maxDisplacement').min(0).max(1);
    gui.add(params, 'noiseFreq').min(0)
    gui.add(params, 'permanenceTime').min(1).max(10);


    gui.add(params, 'targetGeomIndex').min(0).max(sourceGeometries.length - 1).step(1).onFinishChange( //https://github.com/dataarts/dat.gui/blob/master/API.md#Controller+onFinishChange
        function() {
            console.log("CHANGED VALUE");
            state = 'goingToTarget';
            interpolation = 0;

         
        }
    );

  
}

// REF : https://subscription.packtpub.com/book/web-development/9781783981182/1/ch01lvl1sec16/controlling-the-variables-used-in-the-scene

// UpdateGui();

gui.add(bloomParams, 'threshold').min(0).max(1).onChange(function ( value ) {

    bloomPass.threshold = Number( value );

} );
gui.add(bloomParams, 'strength').min(0).max(10).onChange(function ( value ) {

    bloomPass.strength = Number( value );

} );
gui.add(bloomParams, 'radius').min(0).max(10).onChange(function ( value ) {

    bloomPass.radius = Number( value );

} );


/////UPDATE LOOP/////

const clock = new THREE.Clock();
const noise = new perlinNoise3d();



var interpolation = 0;

var goingToTarget = true;
var targetHasBeenReached = false;
var idle = true;

var state = 'idle' // 'goingToTarget' 'goingToIdle' 'targetReached'


function render() {

    //COULD SET UP A STATE MACHINE WITH SWITCHES

    switch (state) {
        case 'idle':
            IdleLoop(0, 1);
            break;
        case 'goingToTarget':
            Transitions(params.targetGeomIndex, 0.001, 'targetReached');
            break;
        case 'goingToIdle':
            Transitions(0, 0.001, 'idle');
            break;
        case 'targetReached':

            setTimeout(() => { state = 'goingToIdle' }, params.permanenceTime * 1000);
            break;
    }
    console.log("STATE " + state);




    pointsGeometry.attributes.position.needsUpdate = true;
    // renderer.render(scene, camera);
    composer.render();


}
// renderer.setAnimationLoop(render);



function Transitions(indexOfTargetGeometry, interpolationSpeed, returnState) {


    interpolation += interpolationSpeed;
    var positions = pointsGeometry.attributes.position.array;

    if (interpolation + interpolationSpeed >= 0.1) { //unsure why the limit has to be so low (0.1) ..need to check this!
        state = returnState;
        interpolation = 0;

    } else {

        for (let i = 0; i < particleCount * 3; i++) {

            let newPoint = MathUtils.lerp(positions[i], targetGeometries[indexOfTargetGeometry].finalPositions[i], interpolation); //this noise makes it "sluggish" kinfda like it
            positions[i] = newPoint;

        }

    }

}




function IdleLoop(baseIdleIndex1, baseIdleIndex2) {
    const elapsedTime = clock.getElapsedTime();
    interpolation = Math.sin(elapsedTime * params.noiseFreq) * params.maxDisplacement; //THIS IS RESPONSIBLE FOR THE JUMP!
    var positions = pointsGeometry.attributes.position.array;
    for (let i = 0; i < particleCount * 3; i++) {

        let newPoint = MathUtils.lerp(targetGeometries[baseIdleIndex1].finalPositions[i], targetGeometries[baseIdleIndex2].finalPositions[i], interpolation * MathUtils.clamp(noise.get(i, elapsedTime / 5) * 2, 0, 1)); //**possibly add noise here
        positions[i] = newPoint;
    }
}


function GeneratePointsList(sampledMesh, particleNumber) {
    var sampler = new MeshSurfaceSampler(sampledMesh).build();
    var pointsPosition = [];
    var tempPosition = new THREE.Vector3();
    for (let i = 0; i < particleNumber; i++) {
        //sample random position in the mesh and assign it to tempPosition
        sampler.sample(tempPosition);
        pointsPosition.push(tempPosition.x, tempPosition.y, tempPosition.z);
    }
    return pointsPosition;
}



function UpdatePointCount(pointCount, samplers, pointsList, pointsGeometry) {
    //update all samplpers
    for (sampler in samplers) {

    }

    var tempVector = new THREE.Vector3();
    for (var i = 0; i < pointCount; i++) {
        sampler.sample(tempVector);
        pointsList.push(tempVector.x, tempVector.y, tempVector.z);
        pointsGeometry.setAttribute("position", new THREE.Float32BufferAttribute(tempVector, 3));
    } //maybe add difference with old point count
}